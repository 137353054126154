@import "~bootstrap/scss/bootstrap";

$container-selects-height: 250px;
$container-preface-height: 550px;
html, body, #root {
  height: 100%;
}
.container-preface {
  max-height: $container-preface-height;
  background: seagreen;
  width: 100%;


}
.image1{
  width: 5vw;
  height: 5vw;
}
h1 {
  margin: 0 !important;
  font-size: 2vw !important;
}

h2 {
 font-size: 1vw !important;

}

.container-page {
  max-height: $container-preface-height;
  background: darkseagreen;
  width: 100%;

}.container-title{


 }

.container-header {
  position: relative;
  max-height: $container-selects-height;
  width: 100%;
}
.container-calendar {
  position: relative;
border-bottom:  1px solid black;
}

.container-selects {
  position: relative;
  max-height: $container-selects-height;
  width: 100%;
  background-color: red;

}

.container-selects_short {
  position: relative;
  max-height: $container-selects-height;
  width: 100%;
  top: 1vh;

}
.container-selects_short2 {
  position: relative;
  max-height: $container-selects-height;
  width: 100%;
  top: 1vh;
 }
.container-selects2 {
  position: relative;
  max-height: 400px;
  width: 100%;
  background-color: green;

}
.container-selects3 {
  position: relative;
  max-height: 400px;
  width: 100%;
  background-color: blue;

}
.container-graph {
  position: relative;
  margin: auto;
  height: 70vh;
  width: 80vw;

}
.container-graph_short {
  position: relative;
  margin: auto;
  margin-top: 1vh;
  height: 70vh;
  width: 80vw;

}
.container-homeGraph1{
  float:left;
  width:50%;
  overflow:hidden;
  height: 60vh;

}
.container-homeGraph2{

  width:50%;
  overflow:hidden;
  height: 60vh;
}

.container-navigation{
background-color: seagreen;
}

.Nav_link:link {
  color: #fff;
  font-size: 1rem;
  line-height: 1rem;
  text-decoration: none;
}
.Nav_link:visited {
  color: #fff;
}
.Nav_link:hover {
  background-color: seagreen;
}
.Nav_link:active {
  background-color: seagreen;
}

.activeRoute {
  background-color: darkseagreen;
  cursor: not-allowed;
}

.container-line_kk {
color: white;
  font-size: 1rem;
}
select {
  font-family: Cursive !important;
  width: 10vw !important;
}

.about{
  font-size: 1.5rem;
  padding-top: 2rem;
  text-align: justify;
  margin-left: 5%;
  margin-right: 5%;
  padding-bottom: 2rem;
}
.about_ref {
  font-size: 2rem;
 padding-top: 2rem;
  padding-bottom: 2rem;
  margin-left: 5%;
  margin-right: 5%;
}
.backround_about {
    background-color: cornsilk;
  position: relative;
  }
.backround_about_ref {
  background-color: bisque;
position: relative;
  top: 0;
}
.images{
  padding-top: 2rem;
  position: relative;
  margin-left: 37%;
  padding-bottom: 2rem;


}

.imgcvut{
  height: 5vw;
}
.imggope{
  height: 5vw;
}
.imgvugtk{
  height: 5vw;
}
.check_box{
  width: 100%;
  height: 3vw;
  background-color: cornsilk;

}

.box{
  width: 100%;
  height: 16vw;
  background-color: bisque;
  border-bottom: 1px solid black;

}

div label input {
  margin-right:100px;
}
body {
  font-family:sans-serif;
}

.ck-button {
  margin-top: 5px;
  margin-left: 1vh;
  background-color:white;
  border:1px solid darkgrey;
  overflow:hidden;
  height: 2.3vw;
  max-width: 10vw;
  float: left;
}



.ck-button label {
  float:left;

}
.ck-button:focus{
  background-color: darkred;
}

.ck-button label span {
  text-align:center;
  display:block;
  font-size: 1.5vw;
}
.ck-button label input {
  position:absolute;
  top:-20px;
}

.ck-button input:checked + span {
  background-color:darkred;
  color:white;
}

.actual_text{
  overflow:hidden;
text-align: center;
}

.container-actualGraph1{
  padding-top: 0.3vw;
  float:left;
  width:30%;
  overflow:hidden;
  height: 11vw;
margin-right: 5%;
}

.container-actualGraph2{
  padding-top: 0.3vw;
  padding-left: auto;
  float:left;
  width:30%;
  overflow:hidden;
  height: 11vw;

}

.container-actualGraph3{
  padding-top: 0.3vw;
  float:right;
  width:30%;
  overflow:hidden;
  height: 11vw;
  margin-right: 0.5vw;
}

.actual_place {
  text-align: center;
}